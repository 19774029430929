import React, { useContext, useState, useEffect } from "react";
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Spinner,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";


// Custom components
import Card from "components/adminPanelComponents/Card/Card.js";
import CardHeader from "components/adminPanelComponents/Card/CardHeader.js";
import CardBody from "components/adminPanelComponents/Card/CardBody.js";
import Pagination from 'components/adminPanelComponents/Pagination';

// Table Components
import UserInfoRow from "components/adminPanelComponents/Tables/UserInfoRow";

import { UserStoreInstanceCTX } from "stores/userStore";

const Users = observer(() => {
  const { 
    getUsersWithPagination,
    isLoadingResultUsersWithPagination,
    resultUsersWithPagination,
    errorsResultUsersWithPagination,
  } = useContext(UserStoreInstanceCTX);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(5);
  const [taskTypeByTitle, setTaskTypeByTitle] = useState();
  const [activeTab, setActiveTab] = useState('')


  useEffect(() => {
    getUsersWithPagination()
  }, [])

  const handlePageChange = async (currentPageValue) => {
    setCurrentPage(currentPageValue)
    await getUsersWithPagination(taskTypeByTitle, currentPageValue, pageLimit)
  }

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>

      {
        resultUsersWithPagination && resultUsersWithPagination?.users && (
            <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px' position="relative">
                {isLoadingResultUsersWithPagination && (
                    <Flex
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        justifyContent="center"
                        alignItems="center"
                        background="rgba(255, 255, 255, 0.3)"
                        zIndex="1"
                    >
                    <Spinner size="lg" color="white" />
                    </Flex>
                )}
                <CardHeader p='6px 0px 22px 0px'>
                    <Flex direction='column'>
                        <Text fontSize='lg' color='#fff' fontWeight='bold' mb='.5rem'>
                            {
                                resultUsersWithPagination?.users.length ? (
                                    `Всего пользователей - ${resultUsersWithPagination?.totalUsers}`
                                ) : (
                                    'Пользователей не найдено'
                                )
                            }
                        </Text>
                    </Flex>
                </CardHeader>
                {
                    resultUsersWithPagination?.users.length ? (
                        <CardBody>
                            <VStack minWidth="100%">
                                <Table variant='simple' color='#fff' minWidth="100%">
                                    <Thead>
                                        <Tr my='.8rem' ps='0px'>
                                            <Th
                                            ps='0px'
                                            color='gray.400'
                                            fontFamily='Plus Jakarta Display'
                                            textAlign="center"
                                            borderBottomColor='#56577A'>
                                            Пользователь
                                            </Th>
                                            <Th
                                            ps='0px'
                                            color='gray.400'
                                            textAlign="center"
                                            fontFamily='Plus Jakarta Display'
                                            borderBottomColor='#56577A'>
                                            Уровень
                                            </Th>
                                            <Th
                                            color='gray.400'
                                            textAlign="center"
                                            fontFamily='Plus Jakarta Display'
                                            borderBottomColor='#56577A'>
                                            Кошелек
                                            </Th>
                                        </Tr>
                                        </Thead>
                                        <Tbody>
                                            { resultUsersWithPagination?.users?.map((user, index, arr) => {
                                                return (
                                                <UserInfoRow
                                                    key={user?._id}
                                                    telegramId={user?.telegramId}
                                                    userName={user?.username}
                                                    email={user?.email}
                                                    userId={user?.id}
                                                    level={user?.level}
                                                    balance={user?.wallet?.balance}
                                                    lastItem={index === arr.length - 1 ? true : false}
                                                />
                                                );
                                            })}
                                        </Tbody>
                                    </Table>
                                    {
                                        resultUsersWithPagination?.users.length && resultUsersWithPagination?.totalPages > 1 && (
                                            <Flex justify="center" align="center" mt={4} mb={4}>
                                                <Pagination 
                                                    currentPage={currentPage} 
                                                    totalPages={resultUsersWithPagination?.totalPages} 
                                                    onPageChange={handlePageChange} 
                                                />
                                            </Flex>
                                        )
                                    }
                            </VStack>
                        </CardBody>
                    ) : ''
                }
            </Card>
        )
      }
    </Flex>
  );
})

export default Users;
