import { createContext } from 'react';
import { makeAutoObservable } from 'mobx';

import { userService } from '../services/user.services';
import { getUserId } from '../utils/cookies';

const USER_TYPES = {
    ADMIN: 'ADMIN',
    USER: 'USER',
}

class UserStore {
    isLoading = false;
    errors = undefined;
    userData = null;
    isUserAuthorized = false;
    isUserAdmin = false;

    isLoadingResultUsersWithPagination = false;
    resultUsersWithPagination = null;
    errorsResultUsersWithPagination = undefined;

    isLoadingResultUserCountry = false;
    resultUserCountry = null;
    errorsResultUserCountry = undefined;



    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
        this.init();
    }

    async init() {
        const userId = getUserId();
        if (userId) {
            await this.getUser(userId);
            await this.checkIfUserIsAdmin(this.userData);
            await this.checkIfUserIsAuthorized(userId);
        }
    }

    checkIfUserIsAuthorized(userId) {
        if(userId) {
            this.isUserAuthorized = true;
        } else {
            this.isUserAuthorized = false;
        }
    }

    async checkIfUserIsAdmin(userData) {
        const typeOfCurrentUser = userData?.roles[0]?.value

        if(typeOfCurrentUser === USER_TYPES.ADMIN) {
            this.isUserAdmin = true;
        } else {
            this.isUserAdmin = false;
        }
    }

    async getUser(userId) {
        this.isLoading = true;
        this.errors = undefined;

        try {
            const response = await userService.getUser(userId);
            this.userData = response.data;
            this.isLoading = false;

            return response.data;
        } catch (error) {
            this.isLoading = false;
            this.errors = error.response?.body?.errors;
            throw error;
        }
    }

    async getUsersWithPagination(
        search = '',
        page = 1,
        limit = 10,
    ) {
        this.isLoadingResultUsersWithPagination = true;
        this.errorsResultUsersWithPagination = undefined;

        try {
            const response = await userService.getUsersWithPagination(
                search,
                page,
                limit,
            );
            this.resultUsersWithPagination = response.data;
            this.isLoadingResultUsersWithPagination = false;

            return response.data
        } catch (error) {
            this.isLoadingResultUsersWithPagination = false;
            this.errorsResultUsersWithPagination = error.response?.body?.errors;
            throw error;
        }
    }

    async updateUserCountry(id, data) {
        this.isLoadingResultUserCountry = true;
        this.errorsResultUserCountry = undefined;

        try {
            const response = await userService.updateUserCountry(id, data);
            this.resultUserCountry = response.data;
            this.isLoadingResultUserCountry = false;

            return response.data
        } catch (error) {
            this.isLoadingResultUserCountry = false;
            this.errorsResultUserCountry = error.response?.body?.errors;
            throw error;
        }
    }
}

export const UserStoreInstance = new UserStore();
export const UserStoreInstanceCTX = createContext(UserStoreInstance);
