import { axiosWithAuth } from "../api/interseptors";
import { saveUserId } from "../utils/cookies";

export const userService = {
  async getUserId() {
    const response = await axiosWithAuth.get(`/api/auth/me/`);
    const id = response.data.id || null;

    if (id) saveUserId(id);

    return id ;
  },

  async getUser(userId) {
    const response = await axiosWithAuth.get(`/api/users/${userId}/`);

    return response;
  },

  async getUsersWithPagination(
    search = '',
    page = 1,
    limit = 10,
  ) {
    const response = await axiosWithAuth.get(`/api/users?page=${page}&limit=${limit}&search=${search}`);

    return response;
  },

  async updateUserCountry(
    userId,
    updatedCountry,
  ) {
    const response = await axiosWithAuth.put(`/api/users/${userId}/country`, updatedCountry);

    return response;
  },
};