import Dashboard from "pagesAdmin/Dashboard.js";
import Billing from "pagesAdmin/Billing.js";
import ProfileAmin from "pagesAdmin/ProfileAmin.js";
import Tasks from "pagesAdmin/Tasks.js";
import Users from "pagesAdmin/Users.js";

import { COMING_SOON_LANDING_ROUTE, LANGUAGES } from "utils/consts";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
} from "components/adminPanelComponents/Icons/Icons";

const adminRoutes = (currLang = LANGUAGES.RU) => {

  return [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <HomeIcon color='inherit' />,
      component: Dashboard,
      layout: `/${currLang}/admin`,
    },
    {
      path: "/tasks",
      name: "Tasks",
      icon: <StatsIcon color='inherit' />,
      component: Tasks,
      layout: `/${currLang}/admin`,
    },
    {
      path: "/users",
      name: "Users",
      icon: <StatsIcon color='inherit' />,
      component: Users,
      layout: `/${currLang}/admin`,
    },
    {
      path: "/billing",
      name: "Billing",
      icon: <CreditIcon color='inherit' />,
      component: Billing,
      layout: `/${currLang}/admin`,
    },
    {
      name: "ACCOUNT PAGES",
      category: "account",
      state: "pageCollapse",
      views: [
        // {
        //   path: "/profile",
        //   name: "Profile",
        //   icon: <PersonIcon color='inherit' />,
        //   secondaryNavbar: true,
        //   component: ProfileAmin,
        //   layout: `/${currLang}/admin`,
        // },
        {
          path: '',
          name: "Лендінг",
          icon: <HomeIcon color='inherit' />,
          secondaryNavbar: true,
          layout: `/${currLang}${COMING_SOON_LANDING_ROUTE}`,
        },
      ],
    },
  ];
}

export default adminRoutes;
