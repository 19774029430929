import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Icon,
  Button,
  useColorModeValue,
  Menu, 
  MenuButton, 
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaEllipsisV, FaCheckCircle } from "react-icons/fa";
import { TbXboxXFilled } from "react-icons/tb";

function UserInfoRow(props) {
  const { 
    level,
    balance,
    linkWithResultFromUser,
    lastItem,
    telegramId,
    email,
    userId,
    userName,
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Tr position="relative">
      <Td
        minWidth={{ sm: "200px" }}
        maxWidth={{ sm: '200px' }}
        ps='0px'
        border={lastItem ? "none" : null}
        borderBottomColor='#56577A'>
        <Flex align='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Flex direction='column'>
            <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                id: <strong>{ userId }</strong>
            </Text>
            <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                userName: <strong>{ userName }</strong>
            </Text>
            {
              email && (
                <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                  email: <strong>{ email }</strong>
                </Text>
              )
            }
            {
              telegramId && (
                <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                    telegramId: <strong>{ telegramId }</strong>
                </Text>
              )
            }
          </Flex>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        maxWidth={{ sm: '200px' }}
        ps='0px'
        borderBottomColor='#56577A'
        border={lastItem ? "none" : null}>
        <Flex alignItems='center' py='.8rem' minWidth='100%' flexWrap='nowrap'>
          <Text fontSize='sm' color='#fff' minWidth='100%' textAlign="center">
            {level}
          </Text>
        </Flex>
      </Td>
      <Td borderBottomColor='#56577A' border={lastItem ? "none" : null}>
        <Text textAlign="center" fontSize='sm' color='#fff' fontWeight='bold' pb='.5rem'>
          {
            balance ? `${balance} (FRT)` : '0'
          }
        </Text>
      </Td>
    </Tr>
  );
}

export default UserInfoRow;
